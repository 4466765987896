@import '~styles/variables';

.container {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  gap: 4px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 10000;
  overflow: hidden;
  background-color: rgba($color: $color-black, $alpha: 0.6);
}

.loader {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 175px;
  height: 150px;
  border-radius: 6px;
  background-color: rgba($color: $color-black, $alpha: 0.2);
}

.text {
  color: $color-white;
  margin-top: 0;
  font-family: $font-roboto;
}