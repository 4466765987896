@import '~styles/variables';

.container {
  background-color: $color-greyish-green-two;
  border-radius: 3px;
  padding: 0.35em 0em;
  width: 100%;
}

.text {
  color: $color-greyish-brown;
  font-family: $font-montserrat;
  font-size: 0.75rem;
  line-height: normal;
  margin: .0rem .5rem;
}