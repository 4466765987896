@import '~styles/variables';

.topContainer {
  display: flex;
  align-items: center;
  padding: 0em 1.25em;
  gap: 1.25em;

  @media (max-width: 768px) {
    height: 60px;
    padding-right: 0;
  }
}