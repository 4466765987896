@import '~styles/variables';

.bigCalendar {
    height: 950px;

    @media (max-width: 992px) {
        height: 600px;
    }

    @media (max-width: 768px) {
        height: 500px;
    }
}

.toolbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: .75rem;

    @media (max-width: 768px) {
        flex-direction: column;
        gap: 8px;
    }
}

.day {
    background-color: $color-white;
    font-family: $font-roboto;
}

.label {
    color: $color-brownish-grey;
    font-family: $font-roboto;
    font-size: 1.25rem;
    font-weight: normal;
    text-transform: capitalize;
    margin: 0;

    @media (max-width: 992px) {
        font-size: 1rem;

    }
}


.beforeNextContainer {
    border-radius: 5px;
    overflow: hidden;
}

.legendContainer {
    display: flex;
    gap: 48px;

    @media (max-width: 1500px) {
        gap: 32px;
    }

    @media (max-width: 1200px) {
        gap: 16px;
    }
}

.legend {
    color: $color-brownish-grey;
    border: none;
    background-color: transparent;
    cursor: pointer;
    font-family: $font-roboto;
    font-size: .875rem;
    font-weight: bold;
    margin: 0;
    padding: 0;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    gap: 8px;

    @media (max-width: 992px) {
        font-size: .75rem;
    }

    @media (max-width: 576px) {
        font-size: .625rem;
    }

    &--hidden {
        font-weight: normal;
        opacity: .5;
    }
}

.navButtons {
    display: flex;
    gap: 10px;

}

.button {
    cursor: pointer;
    color: $color-white;
    background-color: #2E4051;
    border: none;
    padding: 0.425em 0.875em;
    transition: 250ms;

    &:hover {
        background-color: #19262F;
    }
}

.buttonToday {
    cursor: pointer;
    color: $color-white;
    background-color: #2E4051;
    border: none;
    font-family: $font-roboto;
    padding: 0.425em 0.875em;
    border-radius: 5px;
    font-size: .725rem;
    transition: 250ms;

    &:hover {
        background-color: #19262F;
    }
}

.circleYellow {
    display: inline-block;
    width: 16px;
    border-radius: 50%;
    height: 16px;
    background-color: #f99e00;
}

.circleBlue {
    display: inline-block;
    width: 16px;
    border-radius: 50%;
    height: 16px;
    background-color: #5472c9;
}

.circleBlueGreen {
    display: inline-block;
    width: 16px;
    border-radius: 50%;
    height: 16px;
    background-color: $color-blue-sea-two;
}

.circleRed {
    display: inline-block;
    width: 16px;
    border-radius: 50%;
    height: 16px;
    background-color: $color-red;
}


.companyName,
.code,
.status {
    color: $color-brownish-grey;
    font-family: $font-roboto;
    font-size: .875rem;
    font-weight: bold;
    margin: 0;
    text-transform: uppercase;

    @media (max-width: 1200px) {
        font-size: .75rem;
    }

    @media (max-width: 992px) {
        font-size: 0rem;
    }
}


.status {
    font-weight: normal;
}

.eventContainer {
    display: flex;
    margin-left: .5rem;
    margin-bottom: .5rem;
    gap: 8px;
    background-color: $color-white !important;

    @media (max-width: 1200px) {
        margin-left: .25rem;

    }

    @media (max-width: 992px) {
        justify-content: center;
    }
}

.event {
    background-color: $color-white !important;
}


.modalText {
    padding: 0;
    margin: 1rem 0;
    color: $color-brownish-grey;
    font-family: $font-roboto;

    &:first-of-type {
        margin-top: 0;
        width: 90%;
    }

    &:last-of-type {
        margin-bottom: 0;
    }
}