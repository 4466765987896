@import '~styles/variables';

.container {
    background: url('~assets/images/clouds-bg.svg') center center no-repeat $color-pale-grey2;
    background-size: cover;
    padding: 70px 0;
    border-radius: 50px;
    margin: 60px 30px 100px 30px;
    font-family: "Montserrat", sans-serif;
    position: relative;

    @media (max-width: 1400px) {
        padding: 40px 20px 40px 20px;
    }

    @media (max-width: 1200px) {
        background-image: none;
        padding: 40px 20px 40px 20px;
        margin: 30px 0 0 0;
        border-radius: 0;
    }
}

.row {
    max-width: 1240px;
    width: calc(100% - 40px);
    padding: 0 20px;
    margin: 0 auto;
    display: flex;

    @media (max-width: 1200px) {
        flex-direction: column;
        gap: 30px;
    }
}

.titleCol {
    width: 33.333%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (max-width: 1400px) {
        width: 35%;
    }

    @media (max-width: 1200px) {
        width: 100%;
    }
}

.itemsCol {
    width: 66.566%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (max-width: 1400px) {
        width: 65%;
    }

    @media (max-width: 1200px) {
        width: 100%;
    }
}

.title {
    font-size: 40px;
    color: $color-greyish-brown;
    line-height: normal;
    text-transform: uppercase;
    text-align: left;
    text-wrap: balance;
    max-width: 390px;
    margin: 0;

    @media (max-width: 1400px) {
        font-size: 36px;
    }

    @media (max-width: 1200px) {
        text-align: center;
        margin: 0 auto;
        max-width: none;
        font-size: 30px;
    }

    strong {
        font-weight: 600;
        color: $color-blue-sea;
    }
}

.items {
    max-width: 600px;
    margin: 0 auto 30px auto;
    display: flex;
    gap: 75px;

    @media (max-width: 1400px) {
        gap: 60px;
    }

    @media (max-width: 1200px) {
        gap: 20px;
    }
}

.item {
    flex-grow: 1;

    p {
        text-align: center;
        font-size: 24px;
        font-weight: bold;
        text-transform: uppercase;
        margin: 25px 0 0 0;

        @media (max-width: 1400px) {
            font-size: 22px;
        }

        @media (max-width: 1200px) {
            font-size: 16px;
        }
    }
}

.image {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    height: 130px;

    @media (max-width: 1400px) {
        height: 100px;
    }

    @media (max-width: 1200px) {
        height: 60px;
    }

    img {
        @media (max-width: 1400px) {
            height: 100%;
            width: auto;
        }
    }
}

.text {
    max-width: 1170px;
    margin: 0 auto;
    text-align: center;
    text-wrap: balance;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.83;

    @media (max-width: 1400px) {
        font-size: 14px;
    }

    span {
        color: #d667bf;
    }
}

.image1,
.image2,
.image3 {
    display: none;
    position: absolute;

    @media (max-width: 1200px) {
        display: block;
    }
}

.image1 {
    top: 126px;
    left: 0;
}

.image2 {
    top: 270px;
    right: 0;
}