@import '~styles/variables';

.container {
  display: inline-block;
  // width: 85%;
  width: 100%;
  flex: 1;

  @media (max-width: 576px) {
    width: 90%;

  }
}

.inputFile {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

.flexContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  position: relative;
  top: 22px;

  @media (max-width: 900px) {
    top: 2px;
  }

  &--withIcon {
    top: 16px;

    @media (max-width: 900px) {
      top: 3px;
    }
  }
}


.label {
  display: block;
  cursor: pointer;
  color: $color-black;
  width: 100%;
  max-width: 100%;
  justify-content: space-between;
  align-items: center;
  font-family: $font-roboto;
  padding-bottom: 0.375em;
  padding-right: 2rem;
  border-bottom: 1px solid $color-white-two;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  text-overflow: ellipsis;
  white-space: nowrap;

  &::after {
    content: "";
    display: inline-block;
    width: 20px;
    height: 20px;
    background: url("../../../assets/icons/add-icon.svg") no-repeat center;
    position: absolute;
    top: 0;
    right: 0;
  }

  &--disabled {
    color: $color-warm-grey;
    cursor: default;

    &::after {
      display: none;
    }
  }
}

.downloadIcon {
  position: relative;
  top: -4px;
  right: 6px;

  @media (max-width: 900px) {
    top: -1px;
    right: auto;
  }

  &--hidden {
    opacity: 0;

    @media (max-width: 900px) {
      display: none;
    }
  }
}