@import '~styles/variables';

.root {
  display: flex;
  margin: auto;
  color: $color-black;
  background-color: transparent;
  text-decoration: underline;
  border: none;
  cursor: pointer;
  white-space: nowrap;
  font-size: 0.875rem;
  font-family: $font-montserrat;
  text-transform: none;
  width: fit-content;

  &:hover {
    background-color: transparent;
    text-decoration: underline;
  }

  &--bold {
    font-weight: 600;
  }


}

.button {
  &--modal {
    margin-left: 1.25rem;

    @media (max-width: 900px) {
      margin-left: auto;
    }
  }
}