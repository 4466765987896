@import '~styles/variables';


.modalText {
    padding: 0;
    margin: 1rem 0;
    color: $color-brownish-grey;
    font-family: $font-roboto;

    &:first-of-type {
        margin-top: 0;
        width: 90%;
    }
}

.container {
    border-radius: 0px;
    max-width: 600px;
    min-width: 400px;

    @media (max-width: 576px) {
        min-width: 250px;

    }
}