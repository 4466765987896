@import '~styles/variables';

.root {
  background-color: $color-light-blue;
  color: $color-white;
  text-transform: uppercase;
  width: 100%;
  cursor: pointer;
  border: none;
  transition: 250ms;
  font-size: 0.875rem;
  font-family: $font-montserrat;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.26);
  margin-bottom: 0.75rem;
  font-weight: bold;
  padding: 9px 34px;
  white-space: nowrap;
  margin: 0;
  gap: 6px;

  &:hover {
    background-color: $color-light-blue;
    opacity: 0.75;
  }

  &--red {
    background-color: #EA5555;

    &:hover {
      background-color: #EA5555;
      opacity: 0.75;
    }
  }

  &.disabled {
    background-color: rgba($color: $color-warm-grey, $alpha: 0.5) !important;
    color: $color-white;
  }

  .c-search-and-filter-container__buttons-container & {
    height: 100%;

    @media (max-width: 768px) {
      height: 100%;
      border-radius: 0;
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
    }
  }

}