@import '~styles/variables';

.container {
  display: flex;
  min-width: 230px;
  width: 100%;
  min-height: 60px;
  flex-direction: column;
  justify-content: flex-end;
}

.slider {
  color: $color-light-blue;
  width: calc(100% - 16px);
  margin-left: 8px;
}

.label {
  color: $color-warm-grey;
  font-size: 0.75rem;
  font-family: $font-roboto;
  font-weight: normal;
  margin-bottom: 0;
  margin-top: 0;
}