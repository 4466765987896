@import '~styles/variables';

.button {
  display: flex;
  background-color: transparent;
  border: none;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  position: relative;
  color: $color-black;
  font-family: $font-roboto;
  font-size: 0.875rem;
  font-weight: 500;
  text-transform: uppercase;
  text-decoration: none;

  @media (max-width: 1200px) {
    display: none;
  }

  &.selected {
    .a-navigation-button__img-container {
      opacity: 1 !important;
    }

    &::after {
      content: '';
      display: inline-block;
      position: absolute;
      left: 0;
      bottom: -23px;
      width: 100%;
      height: 6px;
      background-color: $color-blue-sea;
      border-radius: 3px;
      z-index: 100;
    }
  }
}

.buttonResponsive {
  display: flex;
  background-color: transparent;
  border: none;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  position: relative;
  color: $color-black;
  font-family: $font-roboto;

  text-transform: uppercase;
  text-decoration: none;

  @media (max-width: 1200px) {
    display: flex;
    font-size: 1.375rem;
    gap: 16px;
    font-weight: 100;

    &.selected {
      filter: brightness(0) invert(80%) sepia(19%) saturate(883%) hue-rotate(128deg) brightness(91%) contrast(94%);
      font-weight: bold;

      &::after {
        display: none;
      }
    }
  }

  @media (max-width: 576px) {
    font-size: 1.125rem;
  }
}

.imgContainer {
  display: flex;
  width: 25px;
  opacity: 0.7;
  justify-content: center;
  align-items: center;
}

.imgContainerResponsive {
  display: flex;
  width: 25px;
  opacity: 0.7;
  justify-content: center;
  align-items: center;

  img {
    filter: invert(28%) sepia(27%) saturate(0%) hue-rotate(160deg) brightness(92%) contrast(83%);
  }
}