@import '~styles/variables';

.header {
  display: flex;
  justify-content: space-between;
  background-color: $color-greyish-green;
  align-items: center;
  padding: 7px 24px 0px 12px;

  @media (max-width: 1200px) {
    padding: 0px 1em 0px 0px;
    height: 60px;
  }
}

.leftColumn {
  display: flex;
  align-items: center;
  height: 100%;
  gap: 14px;

  img {
    height: 60px;

    @media (max-width: 1200px) {
      height: 50px;
    }
  }
}

.icon {
  @media (max-width: 1200px) {
    width: 32px;
    height: 32px;
  }
}

.menu {
  display: none;
  height: 100%;
  background: rgba($color: $color-blue-sea, $alpha: 0.2);
  align-items: center;
  padding: 0 0.5em;
  cursor: pointer;

  @media (max-width: 1200px) {
    display: flex;
  }
}

.icons {
  display: flex;
  align-items: center;
  gap: 8px;

  @media (max-width: 1500px) {
    gap: 4px;
  }

  @media (max-width: 1200px) {
    gap: 0px;
  }

}

.navigation {
  display: flex;
  gap: 34px;
  align-items: center;

  @media (max-width: 1500px) {
    gap: 24px;
  }
}

.button {
  align-self: center;
}