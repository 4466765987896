@import '~styles/variables';

.text {
  color: $color-black;
  font-family: $font-roboto;
  text-align: center;
  line-height: 1.63;

  @media (max-width: 768px) {
    font-size: 0.75rem;
  }
}