@import '~styles/variables';

.breadcrumbs {
  margin-bottom: 0.25rem;
  display: inline-block;
  font-family: $font-montserrat;
  font-size: 0.75rem;
  letter-spacing: normal;
  color: $color-greyish-brown;
  margin-left: .2rem;

  @media (max-width: 992px) {
    font-size: 0.65rem;
  }

  @media (max-width: 576px) {
    font-size: 0.575rem;
  }
}

.separator {
  margin: 0.25rem;
  color: $color-blue-sea;
}

.link {
  &--disabled {
    pointer-events: none;
    text-decoration: none;
  }
}

.item {
  &:last-of-type {

    .link {
      pointer-events: none;
      text-decoration: none;
    }
  }
}

.text {
  display: inline-block;
  font-family: $font-montserrat;
  font-size: 0.75rem;
  letter-spacing: normal;
  color: $color-greyish-brown;
  margin: 0;

  @media (max-width: 992px) {
    font-size: 0.65rem;
  }

  @media (max-width: 576px) {
    font-size: 0.575rem;
  }
}