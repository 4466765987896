@import '~styles/variables';

.root {
  background-color: $color-light-blue;
  color: $color-white;
  text-transform: uppercase;
  width: 100%;
  cursor: pointer;
  border: none;
  transition: 250ms;
  font-size: 0.875rem;
  font-family: $font-montserrat;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.26);
  margin-bottom: 0.75rem;
  font-weight: bold;

  white-space: nowrap;
  margin: 0;
  gap: 6px;

  border-radius: 4px;

  &:hover {
    background-color: $color-light-blue;
    opacity: 0.75;
  }
}

.icon {
  filter: brightness(0) invert(1);
  right: auto;
  left: 14px;
  top: 50%;
  transform: translateY(-50%);
}

.selectContainer {
  padding: 0 !important;
  padding: .725em 1.875em .725em 2.85em !important;
}