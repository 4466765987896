@import '~styles/variables';

.container {
  display: inline-block;
  width: 100%;

  &--filter {
    display: inline-flex;
    margin-bottom: .5rem;
    align-items: center;
    max-width: 250px;

    @media (max-width: 1500px) {
      max-width: 200px;

    }

    @media (max-width: 1200px) {
      max-width: 150px;

    }

    @media (max-width: 768px) {
      max-width: 125px;
      margin-bottom: 0.75rem;

    }
  }

  &--no-margin-label {
    .label {
      margin-bottom: 0;
    }
  }


}

.label {
  color: $color-warm-grey;

  &:global(.MuiInputLabel-shrink),
  &:global(.Mui-focused) {
    color: $color-warm-grey;
    transform: translate(0, 3px) scale(0.75);
  }
}

.select {

  width: 100%;
  font-family: $font-roboto;
  color: $color-black;
  font-size: 1rem;

  &::before {
    border-bottom: 1px solid $color-white-two;
  }

  &::after {
    display: none;
  }

}

.input {
  &:focus {
    background-color: transparent;
  }
}

.disabled {
  color: $color-warm-grey;
}

.list {
  padding: 0px !important;
}

.item {
  min-height: 36px;
}