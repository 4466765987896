@import '~styles/variables';

.form {
  max-width: 230px;
  margin: auto;
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  gap: 30px;

  @media (max-width: 768px) {
    margin-top: 1.5rem;
    max-width: 300px;
  }
}

.fields {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.actions {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}