@import '~styles/variables';

.container {
  display: inline-block;
  width: 100%;

  &--small {
    min-width: auto;
    width: 100px;
  }
}

.label {
  color: $color-warm-grey;
  font-size: 0.75rem;
  font-family: $font-roboto;
  font-weight: normal;
  margin-bottom: 0;
}

.input {
  color: $color-black;
  border-bottom: 1px solid $color-white-two;
  font-family: $font-roboto;
  padding-left: 0;
  background-color: white;
}