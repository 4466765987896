@import '~styles/variables';


.container {
  padding: 0;
  border-radius: 50px;
  margin: 60px 30px 0px 30px;
  font-family: "Montserrat", sans-serif;
  position: relative;

  @media (max-width: 1400px) {
    margin-top: 100px;
  }
}

.title {
  font-size: 46px;
  color: $color-greyish-brown;
  line-height: normal;
  text-transform: uppercase;
  margin: 0 auto;
  text-align: center;
  max-width: 670px;
  padding: 0 20px;

  @media (max-width: 768px) {
    font-size: 30px;
  }

  strong {
    display: block;
    font-weight: bold;
    color: $color-blue-sea;
  }
}

.table {
  position: relative;
  overflow: hidden;
  padding-bottom: 90px;
  margin-top: 60px;

  &:before {
    position: absolute;
    display: block;
    content: '';
    top: 172px;
    width: 100%;
    height: 100%;
    background-color: $color-pale-grey2;
    border-radius: 50px 50px 0 0;
    z-index: 0;
  }

  table {
    z-index: 1;
    position: relative;
    margin: 0 auto;
    max-width: 1170px;
    border-spacing: 0px;
    display: flex;
    flex-direction: column;

    @media (max-width: 1400px) {
      max-width: calc(100vw - 140px);
    }
  }

  tr {
    display: flex;
    width: 100%;
    align-items: center;
  }

  th,
  td {
    width: 33.3333%;
    padding: 0;
    display: flex;
    min-height: 96px;
    align-items: center;
    justify-content: center;
    font-size: 20px;

    span {
      background-repeat: no-repeat;
      background-position: center left;
      padding-left: 60px;
      min-height: 50px;
      display: flex;
      align-items: center;
    }
  }

  tbody {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: white;
    box-shadow: 0 0px 60px -20px rgba(0, 0, 0, 0.33);
    border-radius: 20px 0 20px 20px;
    overflow: hidden;
    display: table-row-group;

    th {
      font-size: 22px;
      font-weight: bold;
      line-height: 1.5;

      @media (max-width: 1400px) {
        font-size: 20px;
      }
    }

    td {
      font-size: 18px;
      font-weight: 500;
      line-height: 1.44;

      @media (max-width: 1400px) {
        font-size: 16px;
      }
    }

    th,
    td {
      padding: 30px;
      min-height: 130px;
      display: table-cell;
      border-top: 1px solid #D5E9EF;

      @media (max-width: 1400px) {
        padding: 20px 30px;
      }

      span {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
      }
    }

    th {
      span {
        align-items: flex-start;
        text-align: left;
      }
    }

    tr {
      display: table-row;

      &:first-child {

        th,
        td {
          border: none;
        }
      }
    }

    td:nth-child(2) {
      color: #343434;
      background-color: #F8F8F8;
    }

    td:nth-child(3) {
      color: #278885;
      background-color: #EAF8F7;
      font-weight: 600;
    }
  }

  thead {
    display: flex;
    flex-direction: column;
    width: 100%;
    z-index: 1;

    th img {
      max-width: 90%;
      height: auto;
    }

    th:nth-child(2) {
      background-color: #dbe7e7;
      border-radius: 20px 0 0 0;
      text-transform: uppercase;
    }

    th:nth-child(3) {
      background-color: #67d6d3;
      border-radius: 0 20px 0 0;
    }
  }
}