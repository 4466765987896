@import '~styles/variables';

.errorBox {
  transition: 350ms;
  opacity: 1;
  background-color: $color-light-pink;
  color: $color-black;
  font-family: $font-roboto;
  font-size: 1rem;
  line-height: 1.38;
  letter-spacing: normal;
  padding: 0.75em 1.5em 1.5em;
  border-radius: 3px;
  margin-bottom: 1.5rem;

  @media (max-width: 992px) {
    font-size: 0.875rem;
    padding: 0.75em 1.25em 0.75em;
    line-height: 1.29;
  }

  &--hidden {
    visibility: hidden;
    position: absolute;
    opacity: 0;
    margin: 0;
    height: 0;
    padding: 0;
  }
}

.text {
  display: flex;
  gap: 16px;
  margin-bottom: 0.25rem;

  @media (max-width: 992px) {
    margin-bottom: 0.5rem;
    gap: 14px;
  }

  &::before {
    content: '';
    display: inline-block;
    min-width: 21px;
    height: 21px;
    filter: invert(51%) sepia(33%) saturate(6105%) hue-rotate(330deg) brightness(97%) contrast(88%);
    background: url('~assets/icons/alert-icon.svg') no-repeat center;
  }
}

.errors {
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 2.25rem;

  @media (max-width: 992px) {
    margin-bottom: 0.25rem;
  }
}