@import '~styles/variables';

.root {
  background-color: $color-white;
  color: $color-light-blue;
  border: 1px solid $color-light-blue;
  text-transform: uppercase;
  width: 100%;
  cursor: pointer;

  transition: 200ms;
  max-width: fit-content;
  font-size: 0.875rem;
  font-family: $font-montserrat;
  margin-bottom: 0.75rem;
  font-weight: bold;
  padding: 9px 1em;
  white-space: nowrap;
  margin: 0;
  gap: 2px;

  &:active {
    transform: translateY(4px);
  }

  &:hover {
    background-color: $color-white;
    opacity: 0.75;
  }


  &.disabled {
    background-color: rgba($color: $color-warm-grey, $alpha: 0.5) !important;
    color: $color-white;
  }

  .c-search-and-filter-container__buttons-container & {
    height: 100%;

    @media (max-width: 768px) {
      height: 100%;
      border-radius: 0;
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
    }
  }

}