@import '~styles/variables';

.subheader {
  background-color: $color-greyish-green-two;
  padding: 5px 16px 0px;
  position: relative;
}

.chipsContainer {
  display: flex;
  gap: 8px;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: 1em;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
}