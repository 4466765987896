@import '~styles/variables';

.container {
  display: inline-block;
  width: 100%;

  &--hidden {
    display: none;
  }
}

.label {
  color: $color-warm-grey;
  font-size: 1rem;
  font-family: $font-roboto;

  &:global(.Mui-focused) {
    color: $color-warm-grey;
  }

  &:global(.Mui-error) {
    color: $color-red;
  }

  &--shrink {
    transform: translate(0px, 3px) scale(0.75);
  }
}


.textField {
  width: 100%;
  font-family: $font-roboto;
  font-size: 1rem;
}

.input {

  &--default {
    color: $color-black;

    &::before {
      border-bottom: 1px solid $color-white-two;
    }
  }

  &--grey {
    color: $color-warm-grey;

    &::before {
      border-bottom: 1px solid $color-white-two;
    }
  }

}

.formHelperText {
  color: $color-red;
}