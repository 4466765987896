@import '~styles/variables';

.anchor {
  position: absolute;
  top: -60px;

  @media (max-width: 1200px) {
    top: -70px;
  }
}

.imageTextBlock {
  font-family: $font-montserrat;
  position: relative;
}

.title {
  font-size: 54px;
  color: $color-greyish-brown;
  line-height: normal;
  text-transform: uppercase;
  margin: 0;

  @media (max-width: 1200px) {
    text-align: center;
  }

  @media (max-width: 768px) {
    font-size: 30px;
  }

  strong {
    font-weight: 900;
    color: $color-blue-sea;

    @media (max-width: 768px) {
      font-weight: bold;
    }
  }
}

.text {
  color: #343434;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.83;
  max-width: 370px;
  margin: 40px 0 40px 70px;

  @media (max-width: 1200px) {
    margin: 40px auto 70px;
    max-width: 100%;
    text-align: center;
  }

  @media (max-width: 768px) {
    font-size: 14px;
    margin: 20px 20px 40px;
    line-height: 1.7;
  }

  ul {
    list-style: none;
    padding-left: 20px;

    li {
      position: relative;

      &::before {
        content: ' ';
        width: 7px;
        height: 7px;
        border: 1px solid $color-blue-sea;
        border-radius: 50%;
        position: absolute;
        left: -20px;
        top: 10px;
      }
    }
  }
}

.content {
  display: flex;
  justify-content: flex-end;

  @media (max-width: 1200px) {
    flex-direction: column-reverse !important;
    align-items: center;
    justify-content: center;
  }

  &.reverse {
    flex-direction: row-reverse;
  }
}

.info {
  width: 660px;
  position: relative;

  @media (max-width: 1200px) {
    width: 100%;
  }
}

.infoWrapper {
  flex: 1;
  display: flex;
  justify-content: center;
  max-width: 100%;
  flex-direction: column;
  align-items: center;
  margin: 0 30px;

  @media (min-width: 1700px) {
    max-width: 40%;
  }

  @media (min-width: 2000px) {
    max-width: 50%;
  }

  @media (max-width: 768px) {
    margin: 0 10px;
  }
}

.image {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 1200px) {
    width: 100%;
    align-items: flex-start;
  }

  img {
    max-width: 100%;
    height: auto;
  }
}

.imageXS {
  display: none;

  @media (max-width: 768px) {
    display: block;
  }
}

.imageXL {
  @media (max-width: 768px) {
    display: none;
  }
}

.buttonWrapper {
  margin: 0 auto;
  width: 100%;
  text-align: center;
}

.button {
  background: $color-pale-magenta;
  color: $color-white;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: bold;
  box-shadow: 0 5px 0 0 $color-purpley-pink;
  text-decoration: none;
  display: inline-block;
  padding: 15px 30px;
  min-width: 282px;
  text-align: center;
  border-radius: 12px;
  transition: box-shadow 1s ease-out, background-color 1s ease-out;
  cursor: pointer;
  position: relative;
  margin-top: 60px;

  &:hover {
    background: lighten($color-pale-magenta, 10%);
    box-shadow: 0 5px 0 0 lighten($color-purpley-pink, 10%);
  }
}