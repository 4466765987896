@import '~styles/variables';

.menu {
  background-color: $color-white;
  padding: 3em 2em 4em;
  width: 100%;
  height: 90vh;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
}

.modal {
  border: none;
}

.paper {
  background-color: transparent !important;
}

.background {
  display: none;
  flex-direction: row-reverse;
  height: 100vh;
  overflow-y: hidden;
  width: 100vw;
  backdrop-filter: blur(12px);
  background-color: rgba(0, 0, 0, 0.4);


  @media (max-width: 1200px) {
    display: flex;
  }


}

.logoContainer {
  display: flex;
  align-items: center;
  margin-bottom: 3rem;

  @media (max-width: 576px) {
    img {
      width: 300px;
    }
  }
}

.container {
  display: flex;
  height: fit-content;
  background-color: $color-blue-sea;
  padding: 1.25em 0.825em;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.buttons {
  display: flex;
  gap: 36px;
  flex-direction: column;
}