@import '~styles/variables';

.anchor {
  position: absolute;
  top: -60px;

  @media (max-width: 1200px) {
    top: -70px;
  }
}

.form {
  background: url('~assets/images/clouds-bg.svg') left bottom no-repeat $color-pale-grey2;
  background-size: cover;
  font-family: $font-montserrat;
  margin: 0 30px;
  border-radius: 0 0 50px 50px;
  position: relative;

  @media (max-width: 768px) {
    margin: 0;
    border-radius: 0;
  }
}

.formWrapper {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  max-width: 1170px;
  margin: 0 auto;
  padding: 0 20px;

  @media (max-width: 1200px) {
    flex-direction: column-reverse;
    align-items: center;
    padding: 30px 20px 0;
  }
}

.formBlock {
  background: $color-white;
  border-radius: 24px;
  box-shadow: 20px 40px 80px 0 rgba(0, 0, 0, 0.1);
  padding: 45px 60px;
  margin: 0 0 65px;
  max-width: 390px;
  width: 100%;
  min-height: 475px;

  @media (max-width: 1200px) {
    padding: 45px 60px 100px;
  }

  @media (max-width: 768px) {
    max-width: 100%;
    padding: 40px 40px 80px;
    width: calc(100% - 80px);
  }
}

.title {
  font-size: 36px;
  color: #1d1d1b;
  text-transform: uppercase;
  margin: 0;
  line-height: 1.4;
  text-align: center;
  position: relative;
  max-width: 500px;
  margin: 0 auto;

  @media (max-width: 1200px) {
    margin: 0;
  }

  &::after {
    content: ' ';
    background: url('~assets/images/arrow.png') no-repeat center center;
    background-size: contain;
    width: 209px;
    height: 64px;
    display: block;
    position: absolute;
    right: -45px;
    bottom: -70px;

    @media (max-width: 1200px) {
      content: none;
    }
  }

  strong {
    font-weight: 800;
  }
}

.formTitle {
  font-size: 20px;
  color: $color-greyish-brown-two;
  margin: 0 0 25px;
  line-height: 1.45;
  text-align: center;
  font-weight: bold;

  @media (max-width: 768px) {
    font-size: 14px;
  }

  strong {
    font-weight: 900;
    color: #e244de;
  }
}

.imageWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.formImage {
  @media (max-width: 1200px) {
    max-width: 100%;
    height: auto;
    width: 400px;
    margin: -135px auto 0;
  }
}

.titleXS {
  display: none;

  @media (max-width: 1200px) {
    display: block;
    order: 1;
    margin: 0 0 25px;
  }
}

.titleXL {
  @media (max-width: 1200px) {
    display: none;
  }
}