@import '~styles/variables';

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5em;
  gap: 14px;
  box-shadow: 0 2px 30px 0 rgba(95, 134, 131, 0.1);
  background-color: $color-greyish-green-two;
  border-top: 1px solid $color-white-two;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 1000;
  left: 0;
}

.container {
  display: flex;
  gap: 24px;

  @media (max-width: 768px) {
    gap: 16px;
  }
}

.button {
  position: relative;
  width: 40px;
  height: 40px;
  right: 45px;
  background-color: $color-white;
  box-shadow: 0 2px 20px 0 rgba(37, 111, 109, 0.2);

  @media (max-width: 768px) {
    right: 40px;
  }
}