@import '~styles/variables';

.box {
  max-width: 490px;
  border-radius: 10px;
  box-sizing: border-box;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
  min-height: 546px;
  background-color: $color-white;
  padding: 30px 64px 76px;

  @media (max-width: 768px) {
    max-width: 400px;
    padding: 30px 40px 2em;
  }

  @media (max-width: 576px) {
    max-width: 315px;
    min-height: auto;
  }
}