@import '~styles/variables';

.root {
  background-color: $color-light-blue;
  color: $color-white;
  text-transform: uppercase;
  width: 100%;
  cursor: pointer;
  border: none;
  transition: 250ms;
  font-size: 0.875rem;
  font-family: $font-montserrat;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.26);
  margin-bottom: 0.75rem;
  font-weight: bold;
  padding: 9px 34px;
  white-space: nowrap;
  margin: 0;
  gap: 6px;
  max-width: 140px;

  &:hover {
    background-color: $color-light-blue;
    opacity: 0.75;
  }

  @media (max-width: 768px) {
    margin-right: 0;
    border-radius: 0;
    border-bottom-right-radius: 3px;
    border-top-right-radius: 3px;
    background-image: url('~assets/icons/white-magnifying-glass.svg');
    background-repeat: no-repeat;
    background-position: center;
    padding: 0;
    font-size: 0;
    width: 60px;
    min-width: 60px;
    height: 100%;
  }
}