@import '~styles/variables';

.container {
  border-radius: 0px;
  max-width: calc(100vw - 30px);
}

.header {
  border: none !important;
}

.body {
  margin: 0 0 0 0;
  padding: 30px;
  position: relative;
  overflow-x: hidden;
}

.title {
  font-family: $font-montserrat;
  font-size: 1.25rem;
  font-weight: bold;
}

.closeIcon {
  width: 32px;
  height: 32px;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
  background: url('~assets/icons/close.svg') no-repeat center center;

  &--black {
    filter: brightness(0);
  }
}