@import '~styles/variables';

.header {
  background: $color-blue-sea;
  font-family: $font-montserrat;
  padding: 20px;
  position: relative;
  top: 0;
  left: 0;
  width: calc(100% - 40px);
  z-index: 10000;
  transition: all 0.2s ease-out;

  @media (max-width: 1200px) {
    padding: 5px 15px;
    height: 55px;
    width: calc(100% - 30px);
    position: fixed;
  }
}

.fixed {
  box-shadow: 0 22px 34px -10px rgba(0, 0, 0, 0.12);

  @media (min-width: 1201px) {
    padding: 5px 20px;
    position: fixed;
  }

  .logo {
    display: flex;

    img {
      @media (min-width: 1201px) {
        width: 160px;
        height: auto;
      }
    }
  }

  .moreInfoLink {
    @media (min-width: 1201px) {
      padding: 10px 30px;
    }
  }
}

.headerWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
}

.nav {
  display: flex;
  align-items: center;
  text-transform: uppercase;
  gap: 25px;
  list-style: none;
  padding: 0;

  @media (max-width: 1200px) {
    display: none;
    gap: 50px;
  }

  a {
    color: #000105;
    font-size: 14px;
    text-decoration: none;

    @media (max-width: 1200px) {
      color: $color-white;
      font-size: 18px;
      font-weight: bold;
    }
  }
}

.moreInfoLink {
  border: 2px solid $color-black;
  border-radius: 25px;
  padding: 15px 30px;
  text-align: center;
  transition: all 0.2s ease-out;

  @media (max-width: 1200px) {
    border-color: $color-white;
    width: calc(100% - 80px);
  }
}

.navLink {
  &+& {
    position: relative;

    &::after {
      content: ' ';
      width: 3px;
      height: 3px;
      background: $color-white;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: -12px;
      transform: translateX(-50%);

      @media (max-width: 1200px) {
        left: 50%;
        top: -25px;
        transform: translateY(-50%);
        width: 10px;
        height: 10px;
        background: $color-blue-sea;
      }
    }
  }
}

.legal {
  display: none;
  text-align: center;
  padding: 0;
  margin: 0;
  flex-direction: column;
  gap: 15px;
  border-top: 1px dashed $color-white;
  margin: 40px 0 0;
  padding: 40px 0 0;

  @media (max-width: 1200px) {
    display: flex;
  }

  a {
    color: $color-blue-sea;
    font-size: 18px;
  }
}

.navLinkXS {
  display: none;

  @media (max-width: 1200px) {
    display: block;
  }
}

.navLinkAccess {
  a {
    color: $color-blue-sea;
    text-decoration: underline;
  }
}

.menuWrapper {
  @media (max-width: 1200px) {
    position: fixed;
    top: 65px;
    left: -100%;
    height: calc(100vh - 120px);
    width: calc(100% - 40px);
    overflow-y: auto;
    background: $color-black;
    padding: 30px 20px;
    z-index: 999;
  }

  &.isActive {
    @media (max-width: 1200px) {
      left: 0;
    }
  }
}

.isActiveMenu {
  position: relative;
  margin-right: 8px;

  &::after {
    content: ' ';
    background: url('~assets/images/arrow-black-xs.svg') center center no-repeat;
    background-size: contain;
    position: absolute;
    bottom: -16px;
    right: -10px;
    width: 61px;
    height: 15px;
    z-index: 999;
  }
}

.mainNav {
  @media (max-width: 1200px) {
    flex-direction: column;
    display: flex;
  }
}

.logo {
  img {
    transition: all 0.2s ease-out;

    @media (max-width: 1200px) {
      width: 166px;
      height: auto;
      position: relative;
      top: 5px;
    }
  }
}

.navXS {
  display: none;
  list-style: none;
  margin: 0;
  padding: 0;
  gap: 10px;

  @media (max-width: 1200px) {
    display: flex;
  }
}