@import '~styles/variables';

.footer {
    color: $color-greyish-brown-two;
    font-size: 18px;
    line-height: 1.3;
    margin: 0;
    font-family: $font-montserrat;
    padding: 30px 50px;

    @media (max-width: 1500px) {
        font-size: 14px;
        padding: 30px 10px;
    }

    a {
        color: inherit;
    }

    ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    p {
        margin: 0;
    }
}

.contact {
    display: flex;
    align-items: center;
    gap: 30px;

    @media (max-width: 1200px) {
        order: 1;
    }

    @media (max-width: 768px) {
        flex-direction: column;
        font-size: 22px;
        font-weight: 500;
    }

    a {
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 12px;

        @media (max-width: 768px) {
            flex-direction: column;
        }

        img {
            @media (max-width: 768px) {
                width: 45px;
            }
        }
    }
}

.footerWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;

    @media (max-width: 1200px) {
        flex-direction: column;
    }

    @media (max-width: 768px) {
        gap: 30px;
    }
}

.nav {
    display: flex;
    align-items: center;
    gap: 30px;

    @media (max-width: 1200px) {
        order: 2;
    }

    @media (max-width: 768px) {
        font-size: 18px;
        flex-direction: column;
        gap: 15px;
    }
}

.copyright {
    @media (max-width: 1200px) {
        order: 3;
    }

    @media (max-width: 768px) {
        font-size: 14px;
    }
}