@import '~styles/variables';

.root {
  &--blue {
    color: $color-blue-sea;
  }

  &--white {
    color: $color-white;
  }
}