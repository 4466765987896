@import '~styles/variables';

.hero {
  background: $color-blue-sea;
  font-family: $font-montserrat;
  padding: 50px 0 50px 0;
  min-height: 550px;



  @media (max-width: 992px) {
    margin-top: 55px;
    padding: 40px 0 40px;
  }

  @media (max-width: 768px) {
    padding: 30px 0 30px;
  }
}

.container {
  display: flex;
  justify-content: center;
  gap: 65px;
  padding: 0 20px;

  @media (max-width: 1200px) {
    flex-direction: column;
    gap: 20px;
    align-items: center;
  }
}

.title {
  font-size: 46px;
  color: $color-black;
  line-height: 1.2;
  margin: 0 0 25px;

  @media (max-width: 1200px) {
    display: none;
  }

  @media (max-width: 768px) {
    font-size: 24px;
  }
}

.infoText {
  font-size: 18px;
  line-height: 1.67;
  margin: 0;

  @media (max-width: 768px) {
    font-size: 14px;
  }
}

.textWrapper {
  max-width: 485px;
  padding: 20px 0 0;

  @media (max-width: 1200px) {
    max-width: 100%;
    margin: 0 30px 25px;
  }

  @media (max-width: 768px) {
    margin: 0 0 25px;
  }
}

.link {
  background: $color-pale-magenta;
  color: $color-white;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: bold;
  box-shadow: 0 5px 0 0 $color-purpley-pink;
  text-decoration: none;
  display: inline-block;
  padding: 15px 5px;
  min-width: 282px;
  text-align: center;
  border-radius: 12px;
  position: absolute;
  bottom: -22px;
  left: 50%;
  transform: translateX(-50%);
  transition: box-shadow 1s ease-out, background-color 1s ease-out;

  &:hover {
    background: lighten($color-pale-magenta, 10%);
    box-shadow: 0 5px 0 0 lighten($color-purpley-pink, 10%);
  }
}

.moreInfo {
  border-radius: 0 24px 24px 24px;
  background: url('~assets/images/quote.svg') 15px 20px no-repeat #85DEDC;
  border: 1px dashed $color-white;
  padding: 30px 50px 45px;
  position: relative;

  &::after {
    content: ' ';
    background: url('~assets/images/arrow-transparent.png') center center no-repeat;
    background-size: contain;
    position: absolute;
    top: -1px;
    left: -20px;
    width: 20px;
    height: 34px;
    z-index: 999;

    @media (max-width: 768px) {
      content: none;
    }
  }

  &::before {
    content: ' ';
    background: #85DEDC;
    position: absolute;
    top: 0;
    left: -1px;
    width: 2px;
    height: 29px;
    z-index: 1000;

    @media (max-width: 768px) {
      content: none;
    }
  }

  @media (max-width: 768px) {
    background-size: 50px;
    padding: 30px 25px 45px;
    border-radius: 24px;
  }
}

.imageWrapper {
  @media (max-width: 1200px) {
    text-align: center;
    margin: 0 30px;
  }

  @media (max-width: 768px) {
    margin: 0 10px;
  }

  .title {
    display: none;

    @media (max-width: 1200px) {
      display: block !important;
      margin: 0 0 45px;
    }
  }

  .blockColor {
    width: 200px;
    height: 200px;
    background-color: red;
  }
}

.image {
  img {
    max-width: 100%;
    height: auto;
  }
}