@import '~styles/variables';

.container {
  border-radius: 20px;
  max-width: calc(100vw - 30px);
  width: 870px;
  overflow: visible;
}

.dialog {
  z-index: 10000;
}

.header {
  border: none !important;
}

.backdrop {
  -webkit-backdrop-filter: blur(24px);
  backdrop-filter: blur(24px);
  background-color: rgba(38, 189, 185, 0.2);
}

.body {
  margin: 0 0 0 0;
  padding: 30px 10px 10px 10px;
  position: relative;
  overflow-x: hidden;

  &:after {
    content: '';
    display: block;
    height: 90px;
    position: absolute;
    bottom: 10px;
    left: 0;
    width: calc(100% - 35px);
    margin-left: 10px;
    pointer-events: none;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #fff 100%);
  }
}

.title {
  font-family: $font-montserrat;
  font-size: 30px;
  text-transform: uppercase;
  margin: 0 auto;
  text-align: center;
  color: #4f4f4f;
  font-weight: 300;

  strong {
    display: block;
    font-weight: 900;
    color: #67d6d3;
  }
}

.closeIcon {
  width: 48px;
  height: 48px;
  cursor: pointer;
  position: absolute;
  top: -26px;
  right: -26px;
  background: url('~assets/icons/close-black.svg') no-repeat center center;
  background-color: #d667bf;
  border-radius: 100%;
  border: 4px solid black;
  -webkit-box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.27);
  -moz-box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.27);
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.27);
  z-index: 1;
}

.logos {
  max-height: 60vh;
  margin-top: 30px;
  overflow-y: auto;
  overflow-x: hidden;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0;
  max-width: 100%;
  padding: 0 10px 0 20px;
  position: relative;

  @media (max-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 576px) {
    grid-template-columns: repeat(2, 1fr);
  }

  // For Google Chrome
  &::-webkit-scrollbar {
    width: 8px;
    height: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: $color-blue-sea;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-track {
    background: white;
  }

  // For Internet Explorer
  & {
    scrollbar-face-color: white;
    scrollbar-track-color: $color-blue-sea;
  }
}

.logo {
  border-top: 1px solid #59afcc33;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    padding: 15px;
  }

  &:nth-child(1) {
    border-top: none;
  }

  &:nth-child(2) {
    border-top: none;
  }

  &:nth-child(3) {
    border-top: none;

    @media (max-width: 576px) {
      border-top: 1px solid #59afcc33;
    }
  }

  &:nth-child(4) {
    border-top: none;

    @media (max-width: 768px) {
      border-top: 1px solid #59afcc33;
    }
  }

  img {
    max-width: 240px;
    height: auto;
    width: 100%;
    object-fit: cover;
  }
}

.emptySlot {
  display: none;
  border-top: 1px solid #59afcc33;

  @media (max-width: 768px) {
    display: block;
  }

  @media (max-width: 576px) {
    display: none;
  }
}