.button {
  align-self: center;
}

.list {
  padding: 0;

  a {
    text-decoration: none;
    color: inherit;
  }
}