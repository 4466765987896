@import '~styles/variables';

.text {
  width: 50%;

  @media (max-width: 1500px) {
    width: 75%;
  }

  @media (max-width: 576px) {
    width: 100%;
  }
}