@import '~styles/variables';

.button {
  display: flex;
  width: fit-content;
  color: $color-white;
  font-family: $font-montserrat;
  font-size: 0.875rem;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: normal;
  background-color: $color-light-blue;
  cursor: pointer;
  border: none;
  margin-bottom: 0.75rem;
  align-items: center;
  gap: 10px;
  border-radius: 20px;
  padding: 8px 16px;
  padding-right: 20px;
  transition: 250ms;

  &:hover {
    background-color: $color-light-blue;
    opacity: 0.75;
  }

  @media (max-width: 768px) {
    position: static;
  }

  &--squared {
    border-radius: 3px;
  }

  &--red {
    background-color: $color-red;

    &:hover {
      background-color: $color-red;
      ;
    }
  }

  &--green {
    background-color: $color-tealish;

    &:hover {
      background-color: $color-tealish;
      ;
    }
  }

  &--small {
    border-radius: 20px;
    padding: 3px 10px;
    font-size: 10px;
    gap: 5px;
    display: flex;
    justify-content: flex-start;
    margin: auto;
    min-width: 115px;
  }
}

.icon {
  fill: white;

  * {
    fill: white;
  }
}