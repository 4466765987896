@import '~styles/variables';

.container {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;

}

.message {
  margin-left: 10px;
  font-family: $font-roboto;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.79;
  letter-spacing: normal;
  color: $color-black;
  max-width: 400px;
}

.action {
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  text-align: right;
  background-color: transparent;
  border: none;
  text-decoration: underline;
  padding: 0;
}

.nestedContainer {
  display: flex;
  align-items: center;

  div {
    min-width: 30px !important;
  }
}

.Toastify {
  &__toast-container {
    width: fit-content !important;
    min-width: 350px;
  }

  &__close-button {
    opacity: 1 !important;
    filter: brightness(0) invert(72%) sepia(24%) saturate(890%) hue-rotate(152deg) brightness(85%) contrast(85%);
    position: absolute;
    top: 5px;
    right: 5px;
  }

  &__toast {
    opacity: 1 !important;
    border-radius: 3px !important;
    box-shadow: 0 10px 20px 0 rgba(20, 78, 77, 0.15) !important;
    background-color: $color-white !important;
    padding: 15px 18px !important;
    min-height: fit-content !important;
    position: relative;
  }

  &__toast-body {
    padding: 0 !important;
    width: 100% !important;
  }
}