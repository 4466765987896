@import '~styles/variables';

.button {
  width: fit-content;
  color: $color-white;
  font-family: $font-montserrat;
  font-size: 0.875rem;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: normal;
  background-color: $color-light-blue;
  cursor: pointer;
  border: none;
  white-space: nowrap;
  margin-bottom: 0.75rem;
  flex-direction: row-reverse;
  align-items: center;
  gap: 16px;
  border-radius: 20px;
  padding: 8px 16px;
  padding-right: 20px;
  z-index: 500;
  margin: 0;
  transition: 250ms;

  &:hover {
    background-color: $color-light-blue;
    opacity: 0.75;
  }

  &--static {
    position: static;
  }
}

.icon {
  margin-left: 0;
  filter: brightness(0) invert(1);
}