@import '~styles/variables';

.searchBar {
  margin-bottom: 1rem;
  width: 100%;
  font-family: $font-roboto;
  color: $color-black;
  font-size: 1rem;
  padding-top: 16px;
  background: url('~assets/icons/magnifying-glass.svg') no-repeat right 70%;

  @media (max-width: 768px) {
    background: none;
    margin-bottom: 1.75rem;
  }
}

.container {
  margin: 0 0rem 0.5rem;
  width: 100%;

  @media (max-width: 768px) {
    margin-bottom: 0;
  }
}

.label {
  color: $color-black;
  top: 15px;

  &:global(.Mui-focused) {
    color: $color-black;
  }

  &--shrink {
    transform: translate(0, 6px) scale(0.75);
  }
}

.input {
  &::before {
    border-bottom: 1px solid $color-white-two;
  }

  &::after {
    display: none;
  }
}