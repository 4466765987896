.column {
  &--no-margin-bottom {
    margin-bottom: 0;
  }
}

.no-xs {
  @media (max-width: 599px) {
    display: none;
  }
}

.no-sm {
  @media (min-width: 600px) and (max-width: 899px) {
    display: none;
  }
}

.no-md {
  @media (min-width: 900px) and (max-width: 1199px) {
    display: none;
  }
}

.no-lg {
  @media (min-width: 1200px) and (max-width: 1535px) {
    display: none;
  }
}

.no-xl {
  @media (min-width: 1536px) {
    display: none;
  }
}