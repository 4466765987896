@import '~styles/variables';

.mediaLinks {
  background: $color-blue-sea;
  font-family: $font-montserrat;
  padding: 50px 0 150px 0;
  text-align: center;
  width: 100%;

  @media (max-width: 992px) {
    padding: 25px 0 120px;
  }

  @media (max-width: 768px) {
    padding: 0px 0 85px;
  }

  .container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    padding: 0;
    max-width: 1030px;
    width: calc(100vw - 30px);
    margin: 0 auto;
  }

  .title {
    font-size: 24px;
    padding-bottom: 20px;
    border-bottom: 1px solid #0000002a;
    width: 100%;
    margin: 0;

    @media (max-width: 1200px) {
      font-size: 20px;
    }
  }

  .images {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 992px) {
      justify-content: center;
    }
  }

  .image {
    height: 65px;
    display: flex;
    align-items: center;

    @media (max-width: 992px) {
      width: 25%;
    }

    @media (max-width: 768px) {
      width: 33%;
    }

    img {
      width: 100%;
    }

    @media (max-width: 1200px) {
      max-width: 200px;
    }

    @media (max-width: 992px) {
      max-width: 200px;
    }
  }
}