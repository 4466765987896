@import '~styles/variables';

.container {
  margin: 0 auto;
  max-width: 1000px;
  width: 100%;
  padding: 15px;
  font-family: $font-montserrat;
  font-size: 0.875rem;
  color: rgb(74, 74, 74);

  @media (max-width: 1200px) {
    margin-top: 60px;
  }

  * {
    line-height: 160%;
  }

  p,
  li,
  ul,
  table,
  td,
  th {
    font-size: 16px;
  }

  a {
    color: inherit;
  }

  table {
    text-align: left;
    width: 100%;
  }

  table,
  tr,
  td,
  th {
    border: 1px solid rgb(74, 74, 74);
    border-collapse: collapse;
  }

  th {
    background-color: #f4f4f4;
  }

  td,
  th {
    padding: 5px;
  }
}