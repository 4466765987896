@import '~styles/variables';

.tabs {
  margin-top: -8px;
}

.scroller {
  overflow-x: scroll !important;

  // TODO: Should be restyled?
  &::-webkit-scrollbar {
    display: none;
  }
}

.indicator {
  background-color: #5dd7d0;
  height: 3px;
}

.tab {
  color: $color-black;
  font-family: $font-montserrat;
  font-size: 0.875rem;
  padding: 2em;
  text-transform: none;
  padding: 1em 3em;
  min-width: 200px;

  @media (max-width: 768px) {
    font-size: 0.75rem;
    padding: 1em 1.5em;
    min-width: 140px;
  }

  &:global(.Mui-selected) {
    color: $color-black;
    background-color: $color-white;
  }
}