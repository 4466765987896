@import '~styles/variables';

.stepper {
  //margin-top: -0.5rem;
  padding: 0;
  // margin-left: -1.25rem;
  padding-top: .5em;
  padding-bottom: 1.75em;
  margin-bottom: 1.75rem;
  border-bottom: 1px dashed $color-white-two;
  flex: 1;

  @media (max-width: 992px) {
    border: none;
    justify-content: center;
    padding-bottom: 0em;
  }
}


.step {
  position: relative;
  padding-right: 2em;
  cursor: pointer;


  @media (max-width: 992px) {
    padding: 0;
  }

  &:first-of-type {
    padding-left: 0;

    .icon {
      padding-left: 0;
    }

    &::after {
      content: '';
      display: inline-block;
      position: absolute;
      right: 0;
      background-color: $color-warm-grey-three;
      height: 10px;
      width: 1px;
      top: 50%;
      transform: translateY(-50%);

      @media (max-width:992px) {
        display: none;
      }
    }
  }

  &:last-of-type {
    &::before {
      content: '';
      display: inline-block;
      position: absolute;
      left: 0;
      background-color: $color-warm-grey-three;
      height: 10px;
      width: 1px;
      top: 50%;
      transform: translateY(-50%);

      @media (max-width:992px) {
        display: none;
      }
    }
  }
}

.stepLabel {
  @media (max-width: 992px) {
    flex-direction: column;
    text-align: center;
  }
}

.text {
  color: $color-warm-grey;
  font-family: $font-roboto;
  font-size: 1rem;
  font-weight: bold;
  margin: 0;

  @media (max-width: 768px) {
    font-size: 0.75rem;

  }
}

.date {
  color: $color-warm-grey;
  font-family: $font-roboto;
  font-size: 0.875rem;
  margin: 0;

  @media (max-width: 768px) {
    font-size: 0.625rem;

  }

}



.connector {
  max-width: 111px;

  @media (max-width: 992px) {
    position: relative;
    top: -16px;
  }

  @media (max-width: 768px) {
    max-width: 77px;
  }
}

.line {
  border: none;
  border-bottom: 1px dashed $color-warm-grey-three;
}

.icon {
  width: 28px;
  height: 28px;
  padding: 0.5em;
  color: $color-white-two;

  &.iconActive {
    color: $color-light-blue;
  }

  @media (max-width: 992px) {
    padding: 0;
    padding-bottom: .25em;
  }
}

.iconText {
  color: $color-white;
  font-family: $font-montserrat;
  font-size: 1rem;
  font-weight: 600;
}

.textContainer {

  &:global(.Mui-active) {

    .date,
    .text {
      color: $color-black;
    }
  }

}

.iconContainer {
  @media (max-width: 992px) {
    padding: 0;
  }
}