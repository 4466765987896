@import '~styles/variables';

.table {
  border: 1px solid $color-pale-grey;
  border-radius: 0;
  max-height: 280px;
  margin-top: 2rem;
}

.virtualScroller {
  &::-webkit-scrollbar {
    @media (max-width: 768px) {
      height: 6px;
      border-radius: 4px;
    }
  }

  &::-webkit-scrollbar-thumb {
    @media (max-width: 768px) {
      background-color: $color-blue-sea;
    }
  }
}

.columnHeaders {
  border: none;
}

.columnHeader {
  color: $color-warm-grey;
  font-family: $font-roboto;
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1.17;
  padding-left: 16px !important;
  position: relative;

  &:focus-within,
  &:focus {
    outline: none !important;
  }

  &::after {
    content: '';
    display: inline-block;
    width: 1px;
    height: 100%;
    position: absolute;
    right: 0;
    background-color: $color-white-two;
    height: 21px;
  }

  .sortIcon {
    //    transform: rotate(180deg);
    opacity: 0.25 !important;
  }

  &--sorted {
    .sortIcon {
      opacity: 1 !important;
    }
  }
}

.columnHeaderTitleContainer {
  justify-content: space-between;
}

.iconButtonContainer {
  visibility: visible !important;
  width: auto !important;
}

.row {
  cursor: pointer;

  &:global(.Mui-selected) {
    background-color: $color-white !important;
  }

  &:global(.Mui-hovered) {
    background-color: transparent !important;
  }

}

.cell {
  color: $color-black;
  font-family: $font-roboto;
  border-bottom: none !important;
  line-height: 1.08;
  font-size: 0.8125rem;
  padding: 0 1.25em !important;

  &:focus-within,
  &:focus {
    outline: none !important;
  }
}

.footerContainer {
  min-height: 0;
  position: absolute;
  height: 0;
  visibility: hidden;
}

.toolbar {
  color: $color-warm-grey;
  font-size: 0.75rem;
  font-family: $font-montserrat;
  font-weight: 500;
  padding-left: 0;

  .tablePaginationInput {
    margin-left: 8px;

    @media (max-width: 768px) {
      display: inline-flex;
    }
  }
}

.tablePaginationSelect {
  color: $color-black;
  font-size: 1rem;
  padding-left: 0;
  padding-right: 38px;
  border-bottom: 1px solid $color-white-two;
  padding-left: 0 !important;
  padding-bottom: 2px;
  display: flex;
  align-items: center;

  &:focus {
    background-color: transparent;
  }
}

.actionButton {
  color: $color-blue-sea-two;

  @media (max-width: 576px) {
    padding: 0.1em;
  }

  &.Mui-disabled {
    color: $color-warm-grey-two;
  }
}

.displayedRows,
.selectLabel {
  font-size: 0.75rem;
  font-weight: 500;

  @media (max-width: 768px) {
    display: block !important;
  }
}

.displayedRows {
  @media (max-width: 768px) {
    font-size: 0.575rem;
  }
}