@import '~styles/variables';

.quote {
  background: url('~assets/images/quote.svg') 15px 20px no-repeat $color-blue-sea;
  box-shadow: 0 22px 34px -10px rgba(0, 0, 0, 0.12);
  border-radius: 24px 24px 0 24px;
  font-family: $font-montserrat;
  display: flex;
  align-items: center;
  gap: 5px;
  position: relative;

  @media (max-width: 768px) {
    background-image: none;
    flex-direction: row-reverse;
    padding: 0 10px;
    gap: 15px;
    border-radius: 20px !important;
  }

  &:after {
    content: ' ';
    background: url('~assets/images/arrow-blue.svg') center center no-repeat;
    background-size: contain;
    position: absolute;
    bottom: -20px;
    right: 0;
    width: 51px;
    height: 20px;

    @media (max-width: 768px) {
      background: url('~assets/images/arrow.blue-xs.svg') center center no-repeat !important;
      bottom: auto !important;
      top: -19px !important;
      left: 50% !important;
      right: auto !important;
      transform: translateX(-50%);
      width: 61px;
      height: 19px;
    }
  }
}

.quoteReverse {
  border-radius: 24px 24px 24px 0;

  &:after {
    content: ' ';
    background: url('~assets/images/arrow-blue-reverse.svg') center center no-repeat;
    right: auto;
    left: -1px;
  }
}

.quoteText {
  font-size: 16px;
  color: #095553;
  font-style: italic;
  line-height: 1.5;
  font-weight: 600;
  margin-left: 70px;

  @media (max-width: 768px) {
    font-size: 14px;
    margin-left: 0;
    text-align: center;
  }
}

.quoteImage {
  margin-top: -120px;
  align-self: flex-start;

  @media (max-width: 1200px) {
    margin-top: -40px;
  }

  @media (max-width: 768px) {
    margin-top: -20px;
  }

  img {
    @media (max-width: 1200px) {
      width: auto;
      height: 250px;
    }

    @media (max-width: 768px) {
      height: 150px;
    }
  }
}