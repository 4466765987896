@import '~styles/variables';

.label {
  color: $color-warm-grey;
  font-family: $font-roboto;
  transform: translate(0px, 12px) scale(1);

  &:global(.Mui-focused),
  &--shrink {
    color: $color-warm-grey;
    transform: translate(0px, -5px) scale(0.75);
  }

  &:global(.Mui-error) {
    color: $color-red;
  }
}

.container {
  display: inline-block;
  width: 100%;
}

.dateContainer {
  width: 100%;
}

.inputContainer {
  width: 100%;
  font-family: $font-roboto;
  color: $color-black;
  font-size: 1rem;

  &:global(.Mui-disabled) {
    .inputOutline {
      border-bottom: 1px solid rgba($color: $color-white-two, $alpha: 0.5);
    }
  }
}

.input {
  padding-top: .75em;
  padding-bottom: 0.3em;
  padding-left: 0;
  width: 100%;
}

.inputOutline {
  border: none;
  border-bottom: 1px solid $color-white-two;
  border-radius: 0;
}