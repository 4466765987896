@import '~styles/variables';

.wrapper {
  font-size: 1em;
  font-weight: normal;
  font-family: $font-roboto;
  display: flex;
  gap: 10px;
}

.icon {
  content: ' ';
  width: 18px;
  height: 18px;
  min-width: 18px;
  border-radius: 100%;
  display: block;
  background: url('~assets/icons/star.png') center center;
}