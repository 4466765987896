@import '~styles/variables';

.button {
  width: fit-content;
  color: $color-white;
  font-family: $font-montserrat;
  font-size: 0.875rem;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: normal;
  background-color: $color-light-blue;
  cursor: pointer;
  border: none;
  white-space: nowrap;
  margin-bottom: 0.75rem;
  flex-direction: row-reverse;
  align-items: center;
  gap: 16px;
  border-radius: 20px;
  padding: 8px 16px;
  padding-right: 20px;
  z-index: 500;
  margin: 0;
  transition: 250ms;

  &:hover {
    background-color: $color-light-blue;
    opacity: 0.75;
  }

  &::after {
    content: '';
    background: url('~assets/icons/plus.svg') no-repeat left;
    display: inline-block;

    width: 14px;
    height: 14px;

    filter: brightness(0) invert(1);
  }

  &.disabled {
    background-color: rgba($color: $color-warm-grey, $alpha: 0.5) !important;
    color: $color-white;
  }


  &--form {
    position: relative;
    bottom: -10px;

    @media (max-width: 768px) {
      position: static;
    }
  }
}