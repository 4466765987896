@import '~styles/variables';

.chip {
  color: $color-white;
  font-family: $font-montserrat;
  font-size: 0.875rem;
  font-weight: bold;
  letter-spacing: normal;
  background-color: $color-light-blue;
  padding: 1em;
  transition: 250ms;

  @media (max-width: 768px) {
    font-size: 0.675rem;
    margin: .5rem 0;
  }

  &:hover {
    background-color: $color-light-blue;
    opacity: 0.5;
  }

  img {
    filter: brightness(0) invert(1);

    @media (max-width: 768px) {
      width: 14px;
    }
  }
}