@import '~styles/variables';

.title {
  color: $color-black;
  font-family: $font-montserrat;
  font-weight: bold;
  text-align: center;

  &--subheader {
    margin: 0;
    text-align: left;
    font-size: 1.5rem;

    @media (max-width: 992px) {
      font-size: 1.25rem;

    }
  }

  &--hero {
    margin: 0;
    text-align: left;
    font-size: 2rem;

    @media (max-width: 1199px) {
      font-size: 1.5rem;
    }

    @media (max-width: 992px) {
      font-size: 1.25rem;
    }
  }

  &--text {
    color: $color-black;
    font-family: $font-roboto;
    font-weight: normal;
    margin-top: -1.25rem;
    margin-bottom: 2rem;
    text-align: left;
    font-size: .875rem;

    @media (max-width: 992px) {

      margin-top: -1rem;
    }
  }

  &--big {
    font-size: 1.5rem;
    margin-bottom: 1.5rem;

    @media (max-width: 768px) {
      font-size: 1.25rem;
      margin-top: 0.5rem;
      margin-bottom: 1.25rem;
    }
  }

  &--medium {
    font-size: 1.25rem;
    margin-bottom: 1.5rem;
    margin-top: 0.5rem;
    text-align: left;

    @media (max-width: 992px) {
      font-size: 1rem;
      margin-bottom: 1rem;
    }
  }
}