@import '~styles/variables';

.box {
  padding: 15px 80px 15px 30px;
  border: solid 1px $color-light-blue;
  max-width: 375px;
  font-family: $font-roboto;
  background-image: url('~assets/icons/download.svg');
  background-position-y: 30px;
  background-position-x: calc(100% - 30px);
  background-repeat: no-repeat;
  background-color: #f6f8f8;
  line-height: 140%;
  font-size: 1em;
  cursor: pointer;
}