@import '~styles/variables';
@import '~styles/fonts';
@import '~styles/mixins';

@import 'animate.css';

.rbc-header {
  color: $color-brownish-grey;
  font-family: $font-roboto;
  font-weight: 500 !important;
}

.rbc-date-cell {
  color: $color-brownish-grey;
}

.rbc-date-cell.rbc-off-range {
  color: #999999;
}

body {
  margin: 0;
  box-sizing: border-box;

  &.locked {
    overflow: hidden;
  }
}

html {
  scroll-behavior: smooth;
}

.hs-form__virality-link {
  display: none;
}

form iframe {
  display: none;
}

.hs-form-field {
  position: relative;
}

.inputs-list {
  position: absolute;
  top: 0;
  left: 0;
}

body {
  overflow-x: hidden;
}

html,
body {
  font-family: $font-montserrat;
}

* {
  @include scrollbars(.5em, $color-blue-sea);
}