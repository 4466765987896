@import '~styles/variables';

.container {
  margin-bottom: 10px;
  width: 100%;
}

.title {
  color: $color-greyish-brown;
  font-size: 0.75rem;
  font-family: $font-montserrat;
  font-weight: normal;
  margin-bottom: 0.5rem;
  margin-top: 0;
}

.buttons {
  background-color: $color-greyish-green-two;
  flex-direction: row;
  border-radius: 3px;
  gap: 36px;
  min-height: 75px;
  padding: 0 2.25em;
  padding-right: 4em;

  @media (max-width: 768px) {
    width: 100%;
    padding: 1em 0;
    flex-direction: column;
    gap: 8px;
  }
}

.label {
  @media (max-width: 768px) {
    margin-left: 1rem;
  }
}

.text {
  color: $color-greyish-brown-two;
  font-family: $font-montserrat;
  font-size: 0.875rem;
}

.radio {
  &:global(.Mui-checked)~.text {
    color: $color-black;
    font-weight: 500;
  }
}