@import '~styles/variables';

.container {
  width: 600px;
}

.inputFile {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

.label {
  cursor: pointer;
  display: flex;
  color: $color-black;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  font-family: $font-roboto;
  padding-bottom: .275em;
  border-bottom: 1px solid $color-white-two;

  &::after {
    content: "";
    display: inline-block;
    width: 20px;
    height: 20px;
    background: url("../../../assets/icons/add-icon.svg") no-repeat center;
    position: relative;
    top: -2px;
  }
}

.flexContainer {
  display: flex;
  gap: .75em;
}

.message {
  font-family: $font-montserrat;
  margin-top: -.5rem;
  margin-bottom: 1.5rem;
}