@import '~styles/variables';

.autocomplete {
  display: inline-block;
  width: 100%;
}

.input {
  border: none;
  border-bottom: 1px solid $color-white-two;
  width: 100%;
  font-family: $font-roboto;
  border-radius: 0;
  padding: 0;
  color: $color-black;
  font-size: 1rem;
  padding-left: 0;

  .textFieldRoot {
    padding-left: 0;
    padding-bottom: 0.25em;

    &:focus {
      background-color: transparent;
    }

    .endAdornment {
      right: -6px;
      top: 15px;
    }

    .textFieldInput {
      padding-left: 0;
      padding-bottom: 0;
    }
  }
}

.paper {
  min-width: 300px;
  padding: 0;
}

.notchedOutline {
  border: none;
  width: 100%;
  font-family: $font-roboto;
  border-radius: 0;
  padding: 0;
  color: $color-black;
  font-size: 1rem;
}

.label {
  color: $color-warm-grey;
  font-size: 1rem;
  font-family: $font-roboto;
  transform: translate(0px, 16px) scale(1);

  &:global(.Mui-focused) {
    color: $color-warm-grey;
  }

  &:global(.Mui-error) {
    color: $color-red;
  }

  &--shrink {
    min-width: fit-content;
    transform: translate(0px, -0.5px) scale(0.75);
  }
}

.listbox {
  padding: 0;
}