@import '~styles/variables';

.header {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  background: $color-greyish-green-two;
  padding-bottom: 1.5em;

  @media (max-width: 768px) {
    padding-bottom: 3em;

    img {
      width: 231px;
    }
  }
}