@import '~styles/variables';


.container {
  padding: 0;
  border-radius: 50px;
  margin: 100px 30px 0px 30px;
  font-family: "Montserrat", sans-serif;
  position: relative;

  @media (max-width: 768px) {
    margin: 45px 0;
    border-radius: 0;
    padding: 50px 20px 60px 20px;
  }
}

.title {
  font-size: 46px;
  color: $color-greyish-brown;
  line-height: normal;
  text-transform: uppercase;
  margin: 0 auto 30px auto;
  text-align: center;
  max-width: 670px;
  padding: 0 20px;

  @media (max-width: 768px) {
    font-size: 30px;
  }

  strong {
    display: block;
    font-weight: bold;
    color: $color-blue-sea;
  }
}

.toggleContainer {
  border-radius: 20px;
  margin-top: 20px;
  overflow: hidden;
  cursor: pointer;
  box-shadow: 0 12px 44px -20px rgba(0, 0, 0, 0.33);
}

.toggleHeader {
  background-color: #67d6d3;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  color: #000000;
  min-height: 40px;
  display: flex;
  gap: 15px;
  padding: 20px 50px 20px 15px;
  align-items: center;
  position: relative;

  .active & {
    color: white;
    background-color: #007ea0;

    svg * {
      fill: white;
    }

    &:before {
      transform: translateY(50%) rotate(180deg);
    }
  }

  &:before {
    position: absolute;
    transform: translateY(50%);
    margin-top: -8px;
    right: 20px;
    content: ' ';
    background: url('~assets/icons/caret-white-down.svg') center center no-repeat;
    width: 24px;
    height: 15px;
  }

  svg {
    max-height: 30px;
  }
}

.toggleBody {
  max-height: 0;
  transition: all 250ms;

  .active & {
    max-height: 1000px;
  }
}

.before,
.after {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 20px;

  span {
    background-repeat: no-repeat;
    background-position: center left;
    padding-left: 80px;
    min-height: 50px;
    display: flex;
    align-items: center;
    min-height: 100px;
  }
}

.before {
  background: #f5f5f5;
  font-size: 15px;
  font-weight: 500;
  color: #343434;
  line-height: 1.67;
  border-bottom: 1px solid #D5E9EF;
}

.after {
  background: #ecf9f8;
  font-size: 15px;
  font-weight: bold;
  color: #278885;
  line-height: 1.67;
}