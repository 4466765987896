@import '~styles/variables';

.features {
  position: relative;
  margin: -75px 0 50px;

  @media (max-width: 768px) {
    margin: -35px 0 0 20px;
  }
}

.container {
  max-width: 1240px;
  margin: 0 auto;
  width: 100%;
}

.wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 100px;
  font-family: $font-montserrat;
  padding: 0 20px;

  @media (max-width: 768px) {
    grid-gap: 15px;
  }
}

.text {
  color: #343434;
  line-height: 1.4;
  font-size: 20px;
  font-weight: 600;

  @media (max-width: 768px) {
    font-size: 14px;
  }

  strong {
    color: $color-tealish;
  }
}

.feature {
  text-align: center;
  position: relative;
}

.icon {
  img {
    @media (max-width: 768px) {
      width: 70px;
      height: auto;
    }
  }
}