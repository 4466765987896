@import '~styles/variables';

.container {
  padding: 0;
  margin: 0;
  min-height: calc(100vh - 80px);
  padding-bottom: 80px;

  &--form {
    padding-bottom: 100px;
    min-height: calc(100vh - 100px);
  }

  &--public {
    display: flex;
    flex-direction: column;
    height: 100vh;
    align-items: center;
    justify-content: center;
    padding-bottom: 0;

    background: linear-gradient(180deg,
        $color-greyish-green-two 50%,
        $color-white 50%);
  }
}