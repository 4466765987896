// FONTS
$font-montserrat: 'Montserrat', sans-serif;
$font-roboto: 'Roboto', sans-serif;

// COLORS
$color-black: #000;
$color-white: #fff;
$color-white-two: #dbdbdb;
$color-greyish-brown: #4f4f4f;
$color-brownish-grey: #5e5e5e;
$color-greyish-brown-two: #4a4a4a;
$color-greyish-green: #e2f0ef;
$color-greyish-green-two: #f0f7f7;
$color-warm-grey: #8a8a8a;
$color-warm-grey-two: #9b9b9b;
$color-warm-grey-three: #979797;
$color-pale-grey: #ebedf3;
$color-blue-sea: #67d6d3;
$color-blue-sea-two: #5dd7d0;
$color-twilight-blue: #08465e;
$color-light-blue: #59afcc;
$color-light-pink: #ffefef;
$color-red: #d32f2f;
$color-pale-magenta: #d667bf;
$color-purpley-pink: #b62bb3;
$color-tealish: #29b3ae;
$color-pale-grey2: #e9f8f7;