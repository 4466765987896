@import '~styles/variables';

.counter {
  border-radius: 12px;
  background-color: $color-light-blue;
  font-size: 13px;
  font-weight: 500;
  font-family: $font-montserrat;
  display: flex;
  gap: 10px;
  text-decoration: none;
  padding: 2px 10px 2px 2px;
  height: 18px;
  color: white;
}

.icon {
  content: ' ';
  width: 18px;
  height: 18px;
  min-width: 18px;
  border-radius: 100%;
  display: block;
  background: url('~assets/icons/star.png') center center;
}