@import '~styles/variables';

.tabs {
  background: $color-pale-grey2;
  padding: 60px 0;
  border-radius: 50px;
  margin: 100px 30px;
  font-family: $font-montserrat;
  position: relative;

  @media (max-width: 1200px) {
    margin: 100px 0;
    border-radius: 0;
    padding: 0 10px;
  }
}

.container {
  max-width: 1360px;
  margin: 0 auto;
  padding: 0 20px;

  @media (max-width: 768px) {
    padding: 0;
  }
}

.slide {
  width: 100%;
  overflow: hidden;
}

.tabsList {
  background: $color-white;
  border-radius: 36px;
  box-shadow: 20px 30px 80px -1px rgba(0, 0, 0, 0.07), 0 5px 24px -10px rgba(0, 0, 0, 0.13);
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  padding: 5px;

  @media (max-width: 768px) {
    margin: 0 10px;
  }
}

.tabsContent {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 70px 0 0 0;
  gap: 40px;

  @media (max-width: 1400px) {
    gap: 20px;
    padding: 70px 20px 0;
  }

  @media (max-width: 1200px) {
    justify-content: space-between;
    flex-direction: column-reverse;
    margin-bottom: 0;
    padding-top: 30px;
  }
}

.tabImage {
  width: 50%;
  display: flex;
  justify-content: flex-start;

  @media (max-width: 1200px) {
    display: none;
  }

  img {
    max-width: 100%;
    height: auto;
  }
}

.tabLink {
  font-size: 20px;
  text-transform: uppercase;
  color: $color-greyish-brown;
  background: $color-white;
  padding: 15px 5px;
  border-radius: 35px;
  text-align: center;
  flex: 1;

  @media (max-width: 768px) {
    font-size: 14px;
  }

  &.active {
    color: #095553;
    background: $color-blue-sea;
    font-weight: bold;
  }

  &:hover {
    cursor: pointer;
  }
}

.tabTitle {
  font-size: 54px;
  color: $color-greyish-brown;
  line-height: normal;
  text-transform: uppercase;
  margin: 0;

  @media (max-width: 1200px) {
    text-align: center;
  }

  @media (max-width: 768px) {
    font-size: 30px;
    margin: 0 20px;
  }

  strong {
    font-weight: 900;
    color: $color-blue-sea;
  }
}

.tabText {
  color: #343434;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.83;
  margin: 40px 0 40px 80px;
  max-width: 370px;

  @media (max-width: 768px) {
    font-size: 14px;
    max-width: 100%;
    margin: 0 20px 40px;
  }

  ul {
    list-style: none;
    padding-left: 20px;

    li {
      position: relative;

      &::before {
        content: ' ';
        width: 7px;
        height: 7px;
        border: 1px solid $color-blue-sea;
        border-radius: 50%;
        position: absolute;
        left: -20px;
        top: 10px;
      }
    }
  }
}

.tabInfo {
  width: 50%;
  text-align: left;
  display: flex;
  justify-content: flex-end;

  @media (max-width: 1200px) {
    width: 100%;
    justify-content: center;
  }
}

.tabsContentWrapper {
  @media (max-width: 1200px) {
    position: relative;
    margin-bottom: -40px;
  }
}

.tabColumn {
  max-width: 660px;

  @media (max-width: 1200px) {
    margin: 40px;
    max-width: 100%;
  }

  @media (max-width: 768px) {
    margin: 0 0 40px 0;
  }
}

.topSlider {
  position: relative;
  top: -40px;
  margin-bottom: -40px;

  @media (min-width: 1201px) {
    display: none;
  }
}

.topSliderImage {
  img {
    width: 100%;
  }
}