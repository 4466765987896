@import '~styles/variables';

.container {
  width: calc(100% - 6em);
  padding: 2em 3em 3em;

  @media (max-width: 1500px) {
    padding: 1em 2em 2em;
    width: calc(100% - 4em);
  }

  @media (max-width: 1200px) {
    padding: 1em 1.5em 1.5em 1.5em;
    width: calc(100% - 3em);
  }

  @media (max-width: 900px) {
    background-color: $color-greyish-green;
  }
}