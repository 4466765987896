@import '~styles/variables';

.icon {
  &:hover {
    background-color: transparent;
  }

  @media (max-width: 768px) {
    position: relative;
    padding-right: 0;

    &::after {
      content: '';
      display: none;
      width: 0px;
      height: 0px;
      border-style: solid;
      position: absolute;
      bottom: -15px;
      border-width: 0 10px 17px 10px;
      border-color: transparent transparent #e2f0ef transparent;
    }

    &--show-triangle {
      &::after {
        display: block;
      }
    }
  }
}