@import '~styles/variables';

.root {
  color: $color-black;
  background-color: transparent;
  text-decoration: underline;
  border: none;
  cursor: pointer;
  white-space: nowrap;
  font-size: 0.875rem;
  font-family: $font-montserrat;
  text-transform: none;
  width: fit-content;
  min-width: fit-content;

  &:hover {
    background-color: transparent;
    text-decoration: underline;
  }

  @media (max-width: 768px) {
    margin-right: 0;
    border-radius: 0;
    border-bottom-right-radius: 3px;
    border-top-right-radius: 3px;
    background-image: url('~assets/icons/clean-filter.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    min-width: 20px;
    max-width: none;
    padding: 0;
    font-size: 0;
    height: 100%;
  }
}