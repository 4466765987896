@import '~styles/variables';

.switchContainer {
  margin-left: 0;
  justify-content: space-between;
  width: 100%;
  padding: 10px 0;
}

.label {
  font-size: 0.875rem;
  white-space: nowrap;
}

.switchBase {
  padding: 5px;
  top: 3px;
  left: 3px;

  &:global(.Mui-checked) {
    color: $color-white;

    &+.track {
      opacity: 1;
      background-color: $color-light-blue;
    }
  }
}

.thumb {
  border: 1px solid $color-light-blue;
}