@import '~styles/variables';

.container {
  background-color: $color-greyish-green-two;
  background-position-x: 100%;
  background-position-y: 0;
  background-size: 437px 221px;
  background-repeat: no-repeat;
  min-height: 170px;
  padding: 2.5em;
  display: flex;
  position: relative;
  line-height: 1.40;
  font-family: $font-montserrat;

  @media (min-width: 993px) and (max-width: 1199px) {
    padding: 2em;
    background-size: 219px 110px;
  }

  @media (max-width: 992px) {
    padding: 1.5em;
    background-size: 110px 55px;
  }

  @media (max-width: 576px) {
    background-size: 0;

  }

  &--green {
    background-image: url('~assets/images/hero-background-green.png');
  }

  &--cloud {
    background-image: url('~assets/images/hero-background-cloud.png');
  }
}

.content {
  width: calc(100% - 450px);

  @media (min-width: 993px) and (max-width: 1199px) {
    width: calc(100% - 225px);
  }

  @media (max-width: 992px) {
    width: calc(100% - 110px);
  }

  @media (max-width: 576px) {
    width: 100%;
  }

  p {
    margin: 0 0 20px 0;

    @media (min-width: 993px) and (max-width: 1199px) {
      margin: 0 0 15px 0;
    }

    @media (max-width: 992px) {
      margin: 0 0 10px 0;
    }
  }
}

.imageWrapper {
  position: absolute;
  right: 100px;
  top: -20px;

  @media (min-width: 993px) and (max-width: 1199px) {
    right: 50px;
    top: -10px;
  }

  @media (max-width: 992px) {
    right: 25px;
    top: -5px;
  }

  @media (max-width: 576px) {
    display: none;
  }
}

.image {
  max-height: 280px;

  @media (min-width: 993px) and (max-width: 1199px) {
    max-height: 140px;
  }

  @media (max-width: 992px) {
    max-height: 70px;
  }
}