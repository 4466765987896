@import '~styles/variables';

.anchor {
  position: absolute;
  top: -60px;

  @media (max-width: 1200px) {
    top: -70px;
  }
}

.steps {
  font-family: $font-montserrat;
  background: $color-pale-grey2;
  padding: 30px 20px 110px 20px;
  margin: 0 30px 0;
  border-radius: 0;
  position: relative;

  @media (max-width: 1100px) {
    padding: 110px 20px;
    margin: 50px 30px 0;
    border-radius: 50px 50px 0 0;
  }

  @media (max-width: 768px) {
    margin: 50px 0 0;
    padding: 50px 0;
    border-radius: 0;
  }

  .slick-slide {
    text-align: center !important;
  }
}

.container {
  max-width: 1360px;
  margin: 0 auto;
  width: 100%;
}

.title {
  font-size: 54px;
  color: $color-greyish-brown;
  line-height: normal;
  text-transform: uppercase;
  margin: 0 auto;
  max-width: 670px;
  padding: 0 20px;

  @media (max-width: 768px) {
    font-size: 30px;
  }

  strong {
    font-weight: 900;
    color: $color-blue-sea;
  }
}

.header {
  text-align: center;
}

.text {
  color: $color-black;
  font-size: 18px;
  line-height: 1.67;
  margin: 40px auto 35px;
  max-width: 1170px;
  padding: 0 20px;

  @media (max-width: 768px) {
    font-size: 14px;
    margin: 15px auto 35px;
  }
}

.slideTitle {
  font-size: 22px;
  color: $color-greyish-brown;
  line-height: normal;
  text-transform: uppercase;
  margin: 0;
  font-weight: 900;

  @media (max-width: 768px) {
    font-size: 18px;
  }
}

.slideText {
  color: $color-greyish-brown-two;
  font-size: 18px;
  line-height: 1.67;
  font-weight: 500;

  @media (max-width: 768px) {
    font-size: 14px;
  }
}

.sliderItem {
  text-align: center;
  max-width: 305px;
  width: 100%;
  margin: 0 auto;
}

.count {
  width: 70px;
  height: 70px;
  background: $color-blue-sea;
  color: $color-white;
  font-size: 40px;
  font-weight: 900;
  text-shadow: 0 2px 7px rgba(0, 0, 0, 0.33);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: -35px auto 0;
}

.imageWrapper {
  background: $color-white;
  width: 250px;
  height: 250px;
  box-shadow: 20px 60px 50px -1px rgba(33, 84, 82, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  border-radius: 50%;
}

.image {
  margin: 0 0 35px;
}

.sliderWrapper {
  max-width: 1170px;
  margin: 0 auto;
}

:global {
  .slick-slide {
    text-align: center;
  }

  .slick-dots {
    margin: 0;
    padding: 0;
    display: flex !important;
    list-style: none;
    align-items: center;
    gap: 10px;
    justify-content: center;
    margin: 20px 0 0;

    button {
      font-size: 0;
      color: transparent;
      width: 10px;
      height: 10px;
      background: #d8d8d8;
      border-radius: 50%;
      padding: 0;
      margin: 0;
      display: block;
      border: none;
    }
  }

  .slick-active {
    button {
      width: 15px;
      height: 15px;
      background: $color-blue-sea;
    }
  }

  .slick-slider {
    position: relative;
  }

  .slick-arrow {
    position: absolute;
    top: 105px;
    z-index: 1;
    border: none;
    width: 24px;
    height: 44px;
    background-size: contain !important;
    font-size: 0;
    color: transparent;
    padding: 0;
    margin: 0;
  }

  .slick-next {
    right: 20px;
    background: url('~assets/images/arrow_right.png') center center no-repeat;
  }

  .slick-prev {
    left: 20px;
    background: url('~assets/images/arrow_left.png') center center no-repeat;
  }

  .slick-disabled {
    display: none !important;
  }
}